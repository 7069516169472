<template>
    <router-view></router-view>
</template>
<script>

export default {
    name: "App",
    data() {
        return {
            screenWidth: null
        }
    },
    methods: {},
    created() {

    },
    mounted() {
        this.screenWidth = document.body.clientWidth
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.body.clientWidth
            })()
        }
    },
    watch: {
        screenWidth: {
            handler: function (val) {
                if (val < 1200) {
                    this.$store.commit('setCollapsed', true)
                } else {
                    this.$store.commit('setCollapsed', false)
                }
            },
            immediate: true
        },
    }
}
</script>
<style lang="less">
html, body {
  min-width: 800px;
  width: 100%;
  height: 100%;
  margin: auto;
}


</style>
