import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        collapsed: false,
        userInfo: {
            face: '',
            id: "",
            isSuper: '',
            nickName: "",
            role: "",
            username: "",
        }
    },
    getters: {},
    mutations: {
        setCollapsed(state, val) {
            this.state.collapsed = val
        },
        setUserInfo(state, val) {
            this.state.userInfo = val
        }
    },
    actions: {},
    modules: {}
})
