import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/LoginView'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index/IndexView'),
        meta: {
            title: '首页'
        },
        children: [{
            path: '/order-class',
            name: 'order-class',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '班服订单'
            }
        }, {
            path: '/order-add',
            name: 'order-add',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '加订订单'
            }
        }, {
            path: '/order-present',
            name: 'order-present',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '赠品订单'
            }
        }, {
            path: '/order-sample',
            name: 'order-sample',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '样品订单'
            }
        }, {
            path: '/all-order-class',
            name: 'all-order-class',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '班服订单'
            }
        }, {
            path: '/all-order-add',
            name: 'all-order-add',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '加订订单'
            }
        }, {
            path: '/all-order-present',
            name: 'all-order-present',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '赠品订单'
            }
        }, {
            path: '/all-order-sample',
            name: 'all-order-sample',
            component: () => import('../views/order/MyOrder'),
            meta: {
                title: '样品订单'
            }
        }, {
            path: '/statistics',
            name: 'statistics',
            component: () => import('@/views/statistics/DataStatisticsView'),
            meta: {
                title: '数据统计'
            }
        }, {
            path: '/clothing',
            name: 'clothing',
            component: () => import('@/views/information/ClothingView'),
            meta: {
                title: '服装'
            }
        }, {
            path: '/present',
            name: 'present',
            component: () => import('@/views/information/PresentView'),
            meta: {
                title: '赠品'
            }
        }, {
            path: '/brand',
            name: 'brand',
            component: () => import('@/views/information/BrandView'),
            meta: {
                title: '品牌'
            }
        }, {
            path: '/designer',
            name: 'designer',
            component: () => import('@/views/information/DesignerView'),
            meta: {
                title: '设计师'
            }
        }, {
            path: '/factory',
            name: 'factory',
            component: () => import('@/views/information/FactoryView'),
            meta: {
                title: '工厂'
            }
        }, {
            path: '/user',
            name: 'user',
            component: () => import('../views/management/AccountManager'),
            meta: {
                title: '用户'
            }
        }, {
            path: '/market',
            name: 'market',
            component: () => import('../views/market/Market'),
            meta: {
                title: '市场'
            }
        }, {
            path: '/addOrder',
            name: 'addOrder',
            component: () => import('../views/order/AddOrder'),
            meta: {
                title: '添加订单'
            }
        }, {
            path: '/orderDetails',
            name: 'orderDetails',
            component: () => import('../views/order/OrderDetails'),
            meta: {
                title: '订单详情'
            }
        }, {
            path: '/regional-supervisor',
            name: 'regional-supervisor',
            component: () => import('../views/information/RegionalSupervisorView'),
            meta: {
                title: '区域主管'
            }
        }, {
            path: '/factory-order',
            name: 'factory-order',
            component: () => import('../views/factory/FactoryOrder'),
            meta: {
                title: '工厂订单'
            }
        }, {
            path: '/product_support',
            name: 'product_support',
            component: () => import('../views/information/ProductSupportView'),
            meta: {
                title: ''
            }
        }, {
            path: '/value_added_service',
            name: 'value_added_service',
            component: () => import('../views/information/ValueAddedServiceView'),
            meta: {
                title: ''
            }
        }, {
            path: '/login-log',
            name: 'login-log',
            component: () => import('../views/log/LoginLog.vue'),
            meta: {
                title: ''
            }
        }]
    },

]


const router = new VueRouter({
    mode: 'history',
    routes
})
router.beforeEach((to, from, next) => {
    let mens = JSON.parse(localStorage.getItem('menus'));
    let item = localStorage.getItem('authorization');
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.path != '/login') {
        if (item == null || item == '') {
            next('/login');
        }
    }
    if (to.path == '/login' || to.path == '/' || to.path == 'logout') {
        next();
    } else {
        if (mens.indexOf(to.path) != -1) {
            next();
        } else {
            next('/');
        }
    }
})
export default router
